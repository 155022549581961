import React, { useCallback, useEffect, useState } from "react";
import "./proposal-submitting-frame";

import { FaCheckCircle } from "react-icons/fa";
// @ts-ignore
import { ReactComponent as UpwexIconSVG } from "../../assets/images/upwex-icon.svg";
import { extensionApi } from "../../api";

export const ProposalSubmittingFrame = (): JSX.Element => {
  const queryParams = new URLSearchParams(window.location.search);
  const proposalId = queryParams.get("proposalId") || "";
  const upwexUserStatParam = queryParams.get("upwexUserStat");
  const user = JSON.parse(upwexUserStatParam || "{}");
  const refreshToken = user.rt_score;

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isSynced, setIsSynced] = useState(false);

  useEffect(() => {
    const detectingSynced = setTimeout(() => {
      if (proposalId && refreshToken) {
        fetchBidInvites();
      } else {
        setIsLoading(false);
        setIsError(true);
      }
    }, 7000);

    return () => {
      clearTimeout(detectingSynced);
    };
  }, []);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (!refreshToken && !proposalId) {
        if (event.data.type === "proposal-synced") {
          setIsLoading(false);
          setIsSynced(true);
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const fetchBidInvites = useCallback((): void => {
    setIsLoading(true);
  
      extensionApi
        .getBidInvites(refreshToken)
        .then((response) => {
          let isSynced = false;
          const bids = response.data.bids;
          const invites = response.data.invites;
          if (proposalId) {
            bids.forEach((b: any) => {
              if (b.proposal_id == proposalId) {
                setIsSynced(true);
                setIsLoading(false);
                isSynced = true;
              }
            });
            invites.forEach((inv: any) => {
              if (inv.u_proposal_id == proposalId) {
                setIsSynced(true);
                setIsLoading(false);
                isSynced = true;
              }
            });

            if (!isSynced) {
              setIsSynced(false);
              setIsError(true);
              setIsLoading(false);
            }
          }
        })
        .finally(() => setIsLoading(false));
    }, []);

  return (
    <div className="extension-proposal-submitting">
      <UpwexIconSVG style={{ zIndex: "1", height: "24px", width: "24px" }} />

      {isLoading && !isError && (
        <div className="extension-proposal-submitting__loader">
          <div className="loader">
            <div className="loader-description">
              <div className="loader-line"></div>
            </div>
          </div>
        </div>
      )}

      {isError && !isSynced && (
        <span className="error">
          Proposal has not sync! Please reload the page.
        </span>
      )}

      {!isLoading && isSynced && (
        <>
          <span>
            Proposal has been synced with Upwex <FaCheckCircle />
          </span>
        </>
      )}
    </div>
  );
};
