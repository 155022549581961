import React, { FocusEvent, useEffect, useState } from "react";
import "./extension-profile-edit-form.scss";
import { IProfileEditForm, IResponseError } from "../../data-access";
import { useForm } from "react-hook-form";
import { FormButton, FormInput, FormSelect, FormTextarea } from "../../ui";
import { handleFailedRequest, notify } from "../../util";
import { extensionApi } from "../../api/extension-api";
import { Loader } from "../../ui/loader";
import { FaRegTrashAlt } from "react-icons/fa";

const availabilityOptions = [
  { value: "More than 30 hrs/week", label: "More than 30 hrs/week" },
  { value: "Less than 30 hrs/week", label: "Less than 30 hrs/week" },
  { value: "As needed - open to offers", label: "As needed - open to offers" },
  { value: "None", label: "None" },
];

interface IExtensionProfileEditFormProps {
  profile: any;
  refreshToken: string;
  handleBack: any;
  handleSubmit: any;
}

export const ExtensionProfileEditForm = ({
  profile,
  refreshToken,
  handleBack,
  handleSubmit,
}: IExtensionProfileEditFormProps): JSX.Element => {
  const [isProcessingForm, setIsProcessingForm] = useState<boolean>(false);
  const [newSkill, setNewSkill] = useState<string>("");
  const [generalSkills, setGeneralSkills] = useState<string[]>([]);

  const form = useForm<IProfileEditForm>({
    mode: "all",
    defaultValues: {
      profileTitle: profile.profileTitle,
      profileDescription: profile.profileDescription,
      availability: profile.availability,
      country: profile.country,
      username: profile.username,
    },
  });
  const {
    register,
    watch,
    setValue,
    formState: { errors, isValid },
  } = form;

  useEffect(() => {
    if (profile && profile.skills) {
      setGeneralSkills(profile.skills);
    }
  }, [profile]);

  const handleFormSubmit = (): void => {
    const newProfile = {
      ...profile,
      skills: generalSkills,
      profileTitle: form.getValues("profileTitle"),
      profileDescription: form.getValues("profileDescription"),
      availability: form.getValues("availability"),
      country: form.getValues("country"),
      username: form.getValues("username"),
    };
    setIsProcessingForm(true);
    extensionApi
      .updateUpworkProfile(refreshToken, profile.id, newProfile)
      .then((response: { data: any }) => {
        const profiles = response.data.data;
        notify("success", "Profile have been updated!");
        handleSubmit(profiles);
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text);
      })
      .finally(() => setIsProcessingForm(false));
  };

  const handleAddNewSkill = () => {
    if (newSkill) {
      const dataSkills = generalSkills;
      generalSkills.push(newSkill);
      setGeneralSkills(dataSkills);
      setNewSkill("");
    }
  };

  const handleRemoveNewSkill = (i: number) => {
    const newSkills = generalSkills.filter((item, index) => index !== i);
    setGeneralSkills(newSkills);
  };

  return (
    <>
      {isProcessingForm && (
        <div className="frame-loader">
          <Loader />
        </div>
      )}
      {!isProcessingForm ? (
        <div className="extension-content g-12">
          <div className="create-deal-section">
            <h2 className="section-title">Username</h2>
            <FormInput
              {...register("username", {
                required: "This field is required",
              })}
              value={watch("username")}
              placeholder="Enter Upwork Profile Username"
              error={errors?.username?.message}
              onChange={(e: FocusEvent<HTMLInputElement>) =>
                setValue("username", e?.target?.value, {
                  shouldDirty: true,
                  shouldValidate: true,
                  shouldTouch: true,
                })
              }
            />
          </div>

          <div className="create-deal-section">
            <h2 className="section-title">Country</h2>
            <FormInput
              {...register("country", {
                required: "This field is required",
              })}
              value={watch("country")}
              placeholder="Enter Upwork Profile Country"
              error={errors?.country?.message}
              onChange={(e: FocusEvent<HTMLInputElement>) =>
                setValue("country", e?.target?.value, {
                  shouldDirty: true,
                  shouldValidate: true,
                  shouldTouch: true,
                })
              }
            />
          </div>

          <div className="create-deal-section">
            <h2 className="section-title">Availability</h2>
            <FormSelect
              className="m-b-8"
              {...register("availability", {
                required: "This field is required",
              })}
              value={watch("availability")}
              error={errors?.availability?.message}
              placeholder="None"
              options={availabilityOptions}
              onChange={(id) =>
                setValue("availability", id, {
                  shouldDirty: true,
                  shouldValidate: true,
                  shouldTouch: true,
                })
              }
            />
          </div>

          <div className="create-deal-section">
            <h2 className="section-title">Profile Title</h2>
            <FormInput
              {...register("profileTitle", {
                required: "This field is required",
              })}
              value={watch("profileTitle")}
              placeholder="Enter Upwork Profile Title"
              error={errors?.profileTitle?.message}
              onChange={(e: FocusEvent<HTMLInputElement>) =>
                setValue("profileTitle", e?.target?.value, {
                  shouldDirty: true,
                  shouldValidate: true,
                  shouldTouch: true,
                })
              }
            />
          </div>

          <div className="create-deal-section">
            <h2 className="section-title">Profile Description</h2>
            <FormTextarea
              {...register("profileDescription", {
                required: "This field is required",
              })}
              value={watch("profileDescription")}
              placeholder="Enter Upwork Profile Description"
              error={errors?.profileDescription?.message}
              onChange={(e) =>
                setValue("profileDescription", e?.target?.value, {
                  shouldDirty: true,
                  shouldValidate: true,
                  shouldTouch: true,
                })
              }
              maxLength={100000}
            />
          </div>

          <div className="create-deal-section">
            <h2 className="section-title">Skills</h2>
            <div className="skills-section">
              {generalSkills.map((sk, i) => (
                <span>
                  {sk} <FaRegTrashAlt onClick={() => handleRemoveNewSkill(i)} />
                </span>
              ))}
            </div>
            <div className="skill-add-section">
              <FormInput
                placeholder="Add new skill"
                value={newSkill}
                onChange={(e: FocusEvent<HTMLInputElement>) =>
                  setNewSkill(e?.target?.value)
                }
              />
              <FormButton
                className="extension-form-button primary-ex-btn"
                htmlType="submit"
                disabled={!newSkill}
                onClick={handleAddNewSkill}
              >
                Add
              </FormButton>
            </div>
          </div>

          <FormButton
            className="extension-form-button secondary-ex-btn"
            htmlType="submit"
            loading={isProcessingForm}
            disabled={!isValid || !generalSkills.length || isProcessingForm}
            onClick={handleBack}
          >
            Back
          </FormButton>
          <FormButton
            className="extension-form-button primary-ex-btn"
            htmlType="submit"
            loading={isProcessingForm}
            disabled={!isValid || !generalSkills.length || isProcessingForm}
            onClick={handleFormSubmit}
          >
            Save
          </FormButton>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
