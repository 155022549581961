import moment from "moment";
import React, { useEffect, useState } from "react";
import { Carousel } from "antd";
import { FaTimes } from "react-icons/fa";

import "./news-frame";
import { handleFailedRequest, postExtensionMessage } from "../../util";
import { IResponseError, PostMessageType } from "../../data-access";
import { extensionApi } from "../../api";

const containerStyle: React.CSSProperties = {
  height: "180px",
};

export const NewsFrame = (): JSX.Element => {
  const queryParams = new URLSearchParams(window.location.search);
  const upwexUserStatParam = queryParams.get("upwexUserStat");
  const user = JSON.parse(upwexUserStatParam || "{}");
  const refreshToken = user.rt_score;

  const [slideItems, setSlideItems] = useState<any[]>([]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === "news-data") {
        const news = event.data.data;
        setSlideItems(news);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const handleCloseWindow = () => {
    extensionApi
      .hideNews(refreshToken)
      .then(() => {
        postExtensionMessage("close-news-window" as PostMessageType, {});
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, undefined, error.reason);
      })
      .finally(() => {
        postExtensionMessage("close-news-window" as PostMessageType, {});
      });
  };

  return (
    <div className="extension-news">
      <button className="close-frame-btn" onClick={handleCloseWindow}>
        <FaTimes />
      </button>
      <Carousel
        style={containerStyle}
        draggable={true}
        // autoplay={true}
        // autoplaySpeed={5000}
      >
        {slideItems.map((item) => (
          <div key={item.id}>
            <div className="extension-news__item">
              <div className="extension-news__content">
                <div className="extension-news__info">
                  <h3 className="extension-news__desc">
                    <span className="badge">
                      {moment(item.date).format("Do of MMMM")}
                    </span>
                    {item.title}
                  </h3>
                  <p className="extension-news__title">{item.description}</p>
                  {item.link && item.link_title && (
                    <a
                      className="extension-news__link extension-btn primary-ex-btn"
                      href={item.link}
                      target="_blank"
                    >
                      {item.link_title}
                    </a>
                  )}
                </div>

                <div className="extension-news__image">
                  <img src={item.image} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};
