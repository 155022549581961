import React, { useEffect, useRef, useState } from "react";
import { TypeAnimation } from "react-type-animation";
import "./ai-assistant-frame.scss";

import {
  getAIAssistantData,
  gettingJobAIText,
  gettingJobData,
  postExtensionMessage,
  settingJobAIText,
} from "../../util";
import { extensionApi } from "../../api";
import { IUser, PostMessageType } from "../../data-access";
import { FaUndo } from "react-icons/fa";

export const AIAssistantFrame = (): JSX.Element => {
  const queryParams = new URLSearchParams(window.location.search);
  const upwexUserStatParam = queryParams.get("upwexUserStat");
  const user = JSON.parse(upwexUserStatParam || "{}");
  const refreshToken = user.rt_score;

  const [aiAssistantData, setAIAssistantData] = useState<any>(null);
  const [jobIds, setJobIds] = useState<any>(null);
  const [automaticJobAIAssistant, setAutomaticJobAIAssistant] =
    useState<boolean>(true);
  const [isMeError, setIsMeError] = useState<boolean>(true);
  const [automatic, setAutomatic] = useState<boolean>(false);
  const [profileError, setProfileError] = useState<boolean>(false);
  const [text, setText] = useState<any>("");

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === "ai-assistant-job") {
        const { jobInfo, jobId } = getAIAssistantData(event.data.data);
        localStorage.setItem("jobId", jobId || "");
        setAIAssistantData(jobInfo);
        setJobIds(jobId);
        if (automatic && automaticJobAIAssistant) {
          generateAIJobAssistant(jobInfo, jobId);
        } else if (!automatic) {
          getMe(jobInfo, jobId);
        }
      } else if (event.data.type === "ai-assistant-job-html") {
        const { id, info } = event.data.data;
        setJobIds(id);
        setAIAssistantData(info);
        const jobText = gettingJobAIText(id);
        if (jobText) {
          setText(jobText);
        } else {
          if (automatic && automaticJobAIAssistant) {
            generateAIJobAssistant(info, id);
          } else if (!automatic) {
            getMe(info, id);
          }
        }
      } else if (event.data.type === "ai-assistant-job-id") {
        setJobIds(event.data.data.id);
        const jobText = gettingJobAIText(event.data.data.id);
        setText(jobText);
      } else if (event.data.type === "updating-upwork-profile") {
        const storageJobId = localStorage.getItem("jobId") || "";
        const storageJob = gettingJobData(storageJobId);
        getMe(storageJob, storageJobId);
        setText("");
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    getMe();
  }, [refreshToken]);

  const getMe = (data?: any, jobId?: string) => {
    setIsMeError(false);
    extensionApi
      .getMe(refreshToken)
      .then((response: { data: IUser }) => {
        const me = response.data;
        if (!me?.upwork_profile?.id) {
          setProfileError(true);
        } else {
          setProfileError(false);
        }
        setAutomaticJobAIAssistant(me.automatic_job_ai_assistant || false);
        setAutomatic(true);

        if (me.automatic_job_ai_assistant && data && jobId) {
          generateAIJobAssistant(data, jobId);
        }
      })
      .catch((err) => {
        if (err.response.data.message) {
          if (err.response.status === 401) {
            setText("You are not logged in. Please log in to access Upwex.");
          } else {
            setText(err.response.data.message);
          }
          setIsMeError(true);
        }
      });
  };

  const generateAIJobAssistant = (data = null, jobId: string = "") => {
    const jobText = gettingJobAIText(jobId || jobIds);
    if (!jobText) {
      setAutomaticJobAIAssistant(true);
      const storageData = gettingJobData(jobId || jobIds);
      extensionApi
        .generateAIJobAssistant(
          refreshToken,
          data || aiAssistantData || storageData
        )
        .then((response: { data: any }) => {
          const aiResponse = response.data;
          const text = aiResponse.choices[0].message.content || "";
          setText(text);
          settingJobAIText(jobId || jobIds, text);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            if (err.response.data.message.indexOf("field") >= 0) {
              setText(`Something went wrong! Please, try to refresh the page.`);
            } else {
              setText(`${err.response.data.message}`);
            }
          }
        });
    } else {
      setAutomaticJobAIAssistant(true);
      setText(jobText);
    }
  };

  const reGenerateAIJobAssistant = () => {
    const jobData = gettingJobData(jobIds);
    if (jobData) {
      setAutomaticJobAIAssistant(true);
      extensionApi
        .generateAIJobAssistant(refreshToken, jobData)
        .then((response: { data: any }) => {
          const aiResponse = response.data;
          const text = aiResponse.choices[0].message.content || "";
          setText(text);
          settingJobAIText(jobIds, text);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            setText(err.response.data.message);
          }
        });
    } else {
      setAutomaticJobAIAssistant(true);
      setText("Something went wrong...");
    }
  };

  const handleHeightChange = (height: number) => {
    return "";
  };

  return (
    <>
      {!isMeError ? (
        <>
          {!profileError && automatic && !automaticJobAIAssistant && (
            <div className="ai-assistant__container static-header">
              <div className="ai-assistant__header">
                <h4>AI Job Assistant</h4>
                <button
                  className="extension-btn primary-ex-btn"
                  onClick={() => generateAIJobAssistant(null)}
                >
                  Generate AI Job Assistant
                </button>
              </div>
            </div>
          )}
          {profileError ? (
            <div className="ai-assistant__container">
              <div className="ai-assistant__header">
                <h4>AI Job Assistant</h4>
              </div>

              <div className="ai-assistant__content">
                <div className="ai-assistant__icon">
                  <img src="/upwex-magic.png" />
                </div>
                <div className="ai-assistant__response">
                  <TypeAnimation
                    sequence={[`Please, connect your Upwork account...`]}
                    speed={{ type: "keyStrokeDelayInMs", value: 60 }}
                  />
                </div>
              </div>
            </div>
          ) : (automatic && automaticJobAIAssistant) || !automatic ? (
            <div className="ai-assistant__container">
              <div className="ai-assistant__header">
                <h4>AI Job Assistant</h4>
              </div>
              {/* 
              <button
                className="extension-btn secondary-ex-btn ai-assistant__re-generate-btn"
                onClick={reGenerateAIJobAssistant}
              >
                <FaUndo />
              </button> */}

              <div className="ai-assistant__content">
                <div className="ai-assistant__icon">
                  <img src="/upwex-magic.png" />
                </div>
                {!automatic && <div className="ai-assistant__response"></div>}

                {automatic && automaticJobAIAssistant && (
                  <div className="ai-assistant__response">
                    {text && (
                      <TypingAnimation
                        text={text}
                        speed={5}
                        onHeightChange={handleHeightChange}
                      />
                    )}

                    {!text && (
                      <TypeAnimation
                        sequence={[
                          `One moment, please... AI magic is happening right now...`,
                          1000,
                          "Gathering the most valuable insights about your potential client...",
                          1000,
                          "Checking About the Client...",
                          1000,
                          "Unveiling the secrets to a successful collaboration...",
                          1000,
                          "Analyzing client's profile so you can make an informed decision...",
                          1000,
                        ]}
                        speed={{ type: "keyStrokeDelayInMs", value: 60 }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <div className="ai-assistant__container">
            <div className="ai-assistant__header">
              <h4>AI Job Assistant</h4>
            </div>

            <div className="ai-assistant__content">
              <div className="ai-assistant__icon">
                <img src="/upwex-magic.png" />
              </div>

              <div className="ai-assistant__response">
                <TypingAnimation
                  text={"Something went wrong..."}
                  speed={5}
                  onHeightChange={handleHeightChange}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

interface ITypingAnimationProps {
  text: string;
  speed: number;
  onHeightChange: any;
}

export const TypingAnimation = ({
  text,
  speed,
  onHeightChange,
}: ITypingAnimationProps): JSX.Element => {
  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    if (index < text.length) {
      const timeout = setTimeout(() => {
        setDisplayedText(text.slice(0, index + 1));
        setIndex(index + 1);
      }, speed);
      return () => clearTimeout(timeout);
    }
  }, [index, text, speed]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          postExtensionMessage("change-ai-job-height" as PostMessageType, {
            aiAssistantHeight: entry.contentRect.height + 140,
          });
          onHeightChange(entry.contentRect.height);
        }
      });
      resizeObserver.observe(container);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [onHeightChange]);

  return (
    <div
      ref={containerRef}
      dangerouslySetInnerHTML={{ __html: displayedText }}
    />
  );
};
